import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Global } from '../services/global';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public onLogout: EventEmitter<any> = new EventEmitter<any>();

  private identity: any;
  private token: string;
  private personation_token: string;

  constructor(private http: HttpClient) {
    this.identity = this.getPayload(this.getToken());
    this.personation_token = this.getToken();
    this.getIdentity().then(res => { });
  }

  isAuthenticate(): boolean {
    ///console.log(this.token || localStorage.getItem('token'))
    return !!(this.token || localStorage.getItem('token'));
  }

  private _pendingMe: boolean;
  getIdentity() {
    // if (!this.identity?.role) {
    //   if (!this._pendingMe) { this.identity = await this.me() };
    //   this._pendingMe = true;
    //   setTimeout(() => {
    //     this._pendingMe = false;
    //   }, 2000)
    // }
    return this.identity;
  }

  setIdentity(identity: any): void {
    this.identity = identity;
  }

  getToken() {
    return this.token || localStorage.getItem('token')
  }

  setToken(token: string): void {
    this.token = token;
    this.impersonate(token);
    if (!token) {
      localStorage.removeItem('token');
      return;
    }
    localStorage.setItem('token', token);
  }

  async getPayload(token: string): Promise<any> {
    if (!token) return {};
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');

    let data: any = JSON.parse(window.atob(base64));
    return data;
  }

  async hasRoles(roles: string[]): Promise<boolean> {
    //return true;
    if (!this.getToken()) return false;

    let user_roles: string[] = [];

    if (this.identity) {
      user_roles = this.identity.roles || [];
    } else {
      let payload = await this.getPayload(this.getToken());
      if (payload) {
        user_roles = payload.roles || [];
      }
    }
    return !!user_roles.find(ur => roles.find(r => r == ur))
  }

  impersonate(token: string): void {
    this.personation_token = token;
  }

  getPersonation(): string {
    return this.personation_token || this.getToken();
  }

  logout() {
    this.setToken(null);
    this.setIdentity(null);
    this.onLogout.emit(true);
  }

  me() {
    return this.http
      .get<any>(`${Global.ENDPOINTS.BASE}/users/me`)
      .toPromise().then(user => {
        this.setIdentity(user);
      });
  }

}
