export class Global {
  static ENDPOINTS = {
    // BASE: 'http://localhost:1337',
    // BASE: 'http://192.168.1.146:1337',
    BASE: 'https://unigross-api.oddastudio.com',
  };

  static ROLES = {
    courier: 3,
  };


  static getCurrentPosition(options?: PositionOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        async (result) => {
          resolve(result);
        },
        async (err) => {
          reject(err);
        },
        {
          enableHighAccuracy: options ? (options.enableHighAccuracy || true) : true,
          timeout: options ? (options.timeout || 15000) : 15000,
          maximumAge: options ? (options.maximumAge || null) : null
        }
      );
    })
  }
}
